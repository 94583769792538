import anime from "animejs";

import { $, html } from "@utils/dom";
import { moduleDelays } from "./utils";
import { getCurrentlocale } from "@core/locales"

const SELECTOR = "[data-site-transition]";
const EXIT_CLASSNAME = "--js-windmill-exit";

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-transition__bg', this.el);
    this.top = $('.site-transition__top', this.el);
    this.bottom = $('.site-transition__bottom', this.el);
    this.left = $('.site-transition__left', this.el);
    this.right = $('.site-transition__right', this.el);

    this._alternate_hreflang = null;
    this._alternate_hreflocale = null;
  }

  exit() {
    this.el.classList.remove("pointer-events-none");

    return new Promise((resolve) => {
      const tl = anime.timeline({ duration: 650, easing: 'easeInOutCubic', autoplay: false });
            tl.finished.then(resolve);

      tl.add({
        targets: [this.top, this.bottom],
        scaleY: [0, 1],
      }, 0);

      tl.add({
        targets: [this.left, this.right],
        scaleX: [0, 1],
      }, 0);

      tl.add({
        targets: this.bg,
        opacity: [0, 1],
        duration: 350,
        easing: 'linear',
      }, 200);

      this.el.classList.remove("visibility-hidden");
      html.classList.add(EXIT_CLASSNAME);

      tl.play();
    });
  }

  preentering() {
    moduleDelays(350, 0);
  }

  entering({ next }) {
    //
    // get the alternate link for site-nav, important note: this works only for 2 languages
    //
    const parser = new DOMParser();
    const source = parser.parseFromString(next.html, "text/html");
    const alternateHreflangElement = $(`link[rel='alternate'][hreflang]:not([hreflang='${getCurrentlocale()}'])`, source);
    if(alternateHreflangElement) {
      this._alternate_hreflang = alternateHreflangElement.getAttribute('href');
      this._alternate_hreflocale = alternateHreflangElement.getAttribute('hreflang')
    }
  }

  enter() {
    return new Promise((resolve) => {
      const tl = anime.timeline({ duration: 750, easing: 'easeInOutCubic', autoplay: false });

      tl.add({
        targets: [this.top, this.bottom],
        scaleY: 0,
      }, 0);

      tl.add({
        targets: [this.left, this.right],
        scaleX: 0,
      }, 0);

      tl.add({
        targets: this.bg,
        opacity: 0,
        duration: 250,
        easing: 'linear',
        begin: resolve,
      }, 100);

      tl.finished.then(() => {
        this.el.classList.add("visibility-hidden", "pointer-events-none");
      });

      html.classList.remove(EXIT_CLASSNAME);
      tl.play();
    });
  }

  entered() {
    // find lang-item element
    const languageItemElement = $(`.site-nav .lang-item > a[hreflang*='${this._alternate_hreflocale}']`);

    // change its href value
    if(this._alternate_hreflang && languageItemElement) languageItemElement.setAttribute('href', this._alternate_hreflang);
  }

}

export default SiteTransition;
