/**
* @core/windmill.img-lazyload
* <br><br>
* ## Windmill Images Lazyloading.
*
* - Run IntersectionObserver that remove [loading=lazy] on images that are soon to be in viewport.
* - You can specify a custom IO.root using [data-lazyload-clip=".my-css-selector"]
*
* @module windmill
* @preferred
*/

import { $, $$ } from "@utils/dom";

const CLIP_ATTRIBUTE = "data-lazyload-clip";
const SELECTOR = "img[loading=lazy][data-lazyload]";
const ROOT_MARGIN = "0px 0px 100%";
const CLIP_MARGIN = "500px 0px 0px";

export class WindmillImgLazyload {  

  constructor() {
    this._rootIO = null;
    this._ios = null;
    this._images = null;

    this._onIO = this._onIO.bind(this);
  }

  /**
   * Plugin installation.
   */
  install(windmill) {
    this._rootIO = new IntersectionObserver(this._onIO, { rootMargin: ROOT_MARGIN });
    this._ios = new Map();
    this._images = [];

    windmill.on('exiting', this._onExiting, this);
    windmill.on('done', this._onDone, this);
  }

  _onExiting() {
    // clear all customs IntersectionObserver & clear array
    this._ios.forEach(io => io.disconnect());
    this._ios.clear();

    // unobserve all images from root IO & empty array
    this._images.forEach(img => this._rootIO.unobserve(img));
    this._images.length = 0;
  }
  _onDone(data) {
    // get query selector target
    const container = data.next.container || data.current.container;

    // query images
    const images = $$(SELECTOR, container);
    if( !images || images.length < 1 ) return;

    // observe each images to an IntersectionObserver
    images.forEach(img => {
      // default IO
      let io = this._rootIO;

      // find image's custom IO's root
      if( img.hasAttribute(CLIP_ATTRIBUTE) ) {
        // find image's root element
        const clip = $(img.getAttribute(CLIP_ATTRIBUTE), container);
        if( clip ) {
          // if this custom IO already exists, use it
          // otherwise, create a new IO
          if( this._ios.has(clip) ) io = this._ios.get(clip);
          else {
            io = new IntersectionObserver(this._onIO, { root: clip, rootMargin: CLIP_MARGIN });
            this._ios.set(clip, io);
          }
        }
      }
      
      // observe image
      io.observe(img);

      // add images to array if using default IO
      if( io === this._rootIO ) this._images.push(img);
    });
  }
  _onIO(entries, observer) {
    entries.forEach(entry => {
      const { isIntersecting, target } = entry;
      if( !isIntersecting ) return;

      observer.unobserve(target);
      target.removeAttribute('loading');

      //console.log(target);
    });
  }
}

export default WindmillImgLazyload;
